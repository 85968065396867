

























import { Component, Vue } from 'vue-property-decorator';

@Component({ name: 'FlagshipProfilingStepProcess' })
export default class FlagshipProfilingStepProcess extends Vue {
  i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_process';

  created() {
    // added because the query (GetRetirementInvestorGoalQuery) is fast,and the process is not shown
    setTimeout(() => {
      this.$emit('nextStep');
    }, 2000);
  }
}
